import { atom, useAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { useUserContext } from "../../context/UserContext";
import { reclaim } from "../../reclaim-api";
import {
  InviteSource,
  PartialTeam,
  PartialTeamInvitation,
  PartialTeamMember,
  Team,
  TeamInvitation,
  TeamMember,
} from "../../reclaim-api/team/Team";
import { useRunEffectOnce } from "../useRunEffectOnce";

const teamAtom = atom<Team | null>(null);
const loadingAtom = atom<boolean>(false);
const loadingErrorAtom = atom<boolean>(false);

export type TeamContextActions = {
  invite: (invites: PartialTeamInvitation[], source?: InviteSource) => Promise<TeamInvitation[]>;
  load: () => Promise<void>;
  uninvite: (id: string) => Promise<void>;
  removeMember: (userId: string) => Promise<void>;
  patchMember: (userId: string, member: PartialTeamMember) => Promise<void>;
  patchTeam: (team: PartialTeam) => Promise<void>;
  leaveTeam: () => Promise<void>;
};

export type TeamContext = {
  team: Team | null;
  userMembership: TeamMember | null;
  loading: boolean;
  loadingError: boolean;
  actions: TeamContextActions;
};

export const useTeam = (loadOnMount = false): TeamContext => {
  const [team, setTeam] = useAtom(teamAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const [loadingError, setLoadingError] = useAtom(loadingErrorAtom);

  const [{ user }, actions] = useUserContext();

  const userMembership = useMemo(() => {
    return !user?.id ? null : team?.members?.find((m) => m.teamMemberId?.userId === user?.id) || null;
  }, [team, user?.id]);

  /**
   * Actions
   */
  const load = useCallback(async () => {
    let teamData: Team | null = null;

    void setLoading(true);

    try {
      teamData = await reclaim.team.get();
      void setLoadingError(false);
    } catch (err) {
      void setLoadingError(true);
    } finally {
      void setLoading(false);
    }

    if (!!teamData) {
      await setTeam(teamData);
    } else {
      void setLoadingError(true);
    }
  }, [setTeam, setLoading, setLoadingError]);

  const invite = useCallback(
    async (invites: PartialTeamInvitation[], source?: InviteSource) => {
      let invitations: TeamInvitation[] = [];
      try {
        invitations = await reclaim.team.invite(invites, source);
      } catch (err) {
        throw new Error("Team invitation request failed.", { cause: err });
      }

      await load();

      return invitations;
    },
    [load]
  );

  const uninvite = useCallback(
    async (id: string) => {
      try {
        await reclaim.team.deleteInvitation(id);
      } catch (err) {
        throw new Error("Could not revoke team invitation.", { cause: err });
      }

      await load();
    },
    [load]
  );

  const removeMember = useCallback(
    async (userId: string) => {
      try {
        await reclaim.team.deleteMember(userId);
      } catch (err) {
        throw new Error("Could not remove user from team.", { cause: err });
      }

      await load();
    },
    [load]
  );

  const patchTeam = useCallback(
    async (team: PartialTeam) => {
      try {
        await reclaim.team.patchTeam(team);
      } catch (err) {
        throw new Error("Could not remove user from team.", { cause: err });
      }

      await load();
    },
    [load]
  );

  const patchMember = useCallback(
    async (userId: string, member: PartialTeamMember) => {
      try {
        await reclaim.team.patchMember(userId, member);
      } catch (err) {
        throw new Error("Could not patch team member.", { cause: err });
      }

      // Get updated user if patch is for active user.
      if (userId === user?.id) {
        await actions?.load();
      }

      await load();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [load, user?.id] // Do not include user actions to avoid over rendering
  );

  const leaveTeam = useCallback(async () => {
    try {
      await reclaim.team.leaveTeam();
    } catch (cause) {
      throw new Error("Could not leave team.", { cause });
    }

    // User is leaving current team, refresh the current page.
    window.location.reload();
  }, []);

  /**
   * End Actions
   */

  useRunEffectOnce(() => {
    if (!!loadOnMount) {
      void load();
    }
  });

  return {
    team,
    userMembership,
    loading,
    loadingError,
    actions: { invite, load, uninvite, removeMember, patchMember, patchTeam, leaveTeam },
  };
};
